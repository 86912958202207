//the following is the code for the counter
import React, { Component } from "react";
import { getSampleRes } from "../services";
import './Counter.css'
// class Counter extends Component {

//     constructor(props) {
//         super(props)
//         console.log(props)
//         this.state = {
//             count: 0
//         };
//     }

//     increment = () => {
//         this.setState({
//             count: this.state.count + 1
//         });
//     }

//     decrement = () => {
//         this.setState({
//             count: this.state.count - 1
//         });
//     }
//     render() {
//         return (
//             <div className="counter">
//                 <h4>Current Count:</h4>
//                 <div>
//                     <h1>{this.state.count}</h1>
//                 </div>
//                 <div>
//                     <button onClick={this.increment}>Increment</button>
//                     <button onClick={this.decrement}>Decrement</button>
//                 </div>
//             </div>
//         );
//     }
// }

const Counter = () => {
    const [count, setCount] = React.useState(0);

    const getResponse = () =>{
        getSampleRes().then(
            a =>{
                console.log(a);
            }
        )
    }

    return (
        <React.Fragment>
        
                <h2>Current Count:</h2>
                <div>
                    <h1>{count}</h1>
                    <div>
                        <button onClick={() => setCount(count + 1)} style={{fontSize:"15px"}}>Increment</button>
                        <button onClick={() => setCount(count - 1)} style={{fontSize:"15px"}}>Decrement</button>
                    </div>
                    <button onClick={getResponse}>Test API Response</button>
                </div>

        </React.Fragment>
    );
}



export default Counter;
