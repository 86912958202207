import React, { useState } from 'react'

//this is the calculator component
function GSTCalculator() {
    const [value, setvalue] = useState(0.00);
    const [GSTValue, setGSTValue] = useState(0.00);

    const calculateGSTtotal = () => {
        //get the initial amount before GST and Service charge
        let amtBeforeGST = parseFloat(value);
    
        /*In order to calculate GST and service charge, it is 7% GST and 10% service charge
        however, service charge is also taxable under GST so therefore in order to get the cost price inclusive of GST
        and Service charge, the formula would be the price * 1.177 (0.07 - GST, 0.10 - Svc Charge, 0.007 - GST on top of svc charge)
        */
        let amtAfterGST = (amtBeforeGST * 1.177).toFixed(2);
        setGSTValue(amtAfterGST);
    }
    return (
        <div>
            <h2>Calculate GST & Service Charge</h2>
            <p>The following calculator computes a total value that includes 7% GST and 10% Service Charge</p>
            <div>
                <label style={{fontSize:"15px"}}>
                    Amount (S$):
                        <input type="number" style={{ marginLeft: "10px", fontSize:"15px" }} value={value} onChange={e => setvalue(e.target.value)}></input>
                </label>
                <button onClick={calculateGSTtotal} style={{fontSize:"15px"}}>Calculate</button>
            </div>
            {GSTValue != null &&
                <div>
                    <h3>Total inclusive of GST and Service Charge</h3>
                    <h1>{GSTValue}</h1>
                </div>
            }
        </div >
    );
}

export default GSTCalculator;