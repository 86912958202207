import React from 'react'
import { Link } from 'react-router-dom';
import './ToolTab.css';

function ToolTab() {
    return (
        <div >
            <ul className="toolBar">
                <li>
                    <Link to="/">
                        <button>
                            Counter
                        </button>
                    </Link>
                </li>
                <li>
                    <Link to="/Calculator">
                        <button>
                            GST Calculator
                        </button>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default ToolTab
