import React from 'react';
//import logo from './logo.svg';
import Counter from "./Counter/Counter";
import './App.css';
import ToolTab from './ToolTab/ToolTab';
import { Route, Switch } from 'react-router-dom';
import Calculator from './Calculator';
import List from './List';

function App() {

  return (
    <div className="main">
      <div className="header">
        <h1>
          Simple Tools by Ethan
        </h1>
        <p>
          The following single page application is built using React framework <br></br>
          The application consists of a counter along with a GST Calculator in two separate tabs
        </p>
      </div>
      <ToolTab />
      <div className="card">
        <Switch>
          <Route path="/Calculator" component={Calculator} />
          <Route path="/" component={Counter} />
        </Switch>
      </div>
      <footer style={{ textAlign: "center", padding: "1em" }}>
        Simple Tools by Ethan
      </footer>
    </div>
  );
}

export default App;
